.mainContainer {
    display: flex;
    flex-direction: column;
    float: left;
    align-items: flex-start;
    gap: 15px;
    padding: 30px 30px 30px 30px;
}

.applicationFormContainer {
    padding: 30px 30px 30px 30px;
}