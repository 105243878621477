
.vertical-center {
  top: 10%;
}

.form {
  display: flex;
  gap: 14px;
  flex-direction: column;
  align-items: flex-start;
}

.paper {
  padding: 25px 25px 25px 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}